import React from 'react'
import Img from 'gatsby-image'

export const Staff = members => {
  const staff = members.members
  return (
    <section className="staff row">
      {staff.length > 0 &&
        staff.map((staff, index) => (
          <div className="staff-member col-2 col-3-mob" key={index}>
            <Img
              className="staff-photo"
              fluid={{ ...staff.photo.asset.fluid, aspectRatio: 9 / 16 }}
            />
            <div className="staff-detail">
              <span className="staff-name">{staff.name}</span>
              <span className="staff-role">{staff.role}</span>
            </div>
          </div>
        ))}
    </section>
  )
}
