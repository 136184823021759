import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockText from '../components/block-text'
import { Staff } from '../components/staff'

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityAbout {
        id
        title
        main_img {
          alt
          asset {
            fluid(maxWidth: 1096) {
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
        about_heading
        _rawAboutText
        about_link_text
        staff_heading
        _rawStaffText
        staff {
          name
          role
          photo {
            alt
            asset {
              fluid(maxHeight: 896) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)
  const page = data.sanityAbout

  return (
    <Layout>
      <SEO title={page.title} />
      <div className="container-theme">
        <div className="about container-fluid">
          <section className="about-top row">
            <div className="col-5 col-6-mob">
              <h1>{page.title}</h1>
              <Img
                className="about-top-img"
                fluid={page.main_img.asset.fluid}
                alt={page.main_img.alt}
              />
            </div>
          </section>
          <section className="about-us row">
            <div className="about-us-heading col-3 col-6-mob">
              <h2>{page.about_heading}</h2>
            </div>
            <div className="col-md-4 col-sm-5 col-6-mob">
              <BlockText
                blocks={page._rawAboutText}
                className="about-us-text"
              />
              <Link to="/process">{page.about_link_text}</Link>
            </div>
          </section>
          <section className="about-staff row">
            <div className="about-staff-heading col-3 col-6-mob">
              <h2>{page.staff_heading}</h2>
            </div>
            <div className="col-4 col-6-mob">
              <BlockText
                blocks={page._rawStaffText}
                className="about-staff-text"
              />
            </div>
          </section>
          <Staff members={page.staff} />
        </div>
      </div>
    </Layout>
  )
}

export default About
